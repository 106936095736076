// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/core/Icon.atom';
import { Ace, getAdditionalText, prettyIdentityType } from '@/accessControl/itemAclModal.utilities';
import { HOME_SCREEN_TABS, PERMISSIONS } from '@/main/app.constants';
import { AceCheckbox } from '@/accessControl/AceCheckbox.molecule';
import { OverlayTrigger } from 'react-bootstrap';
import { sqHomeScreenStore } from '@/core/core.stores';

type AclModalMainTableRowProps = {
  ace: Ace;
  index: number;
  popover: (ace: Ace) => any;
  currentUserId: string;
  setPermissions: (permission: string, ace: Ace) => void;
  isAclFromDatasource: boolean;
  displayAceEntries: Ace[];
  isItemAncestorNotCorporate: boolean;
  includeLinksAndCorporateMessage: boolean;
};

export const AclModalMainTableRow: React.FunctionComponent<AclModalMainTableRowProps> = ({
  ace,
  index,
  popover,
  currentUserId,
  setPermissions,
  isAclFromDatasource,
  displayAceEntries,
  isItemAncestorNotCorporate,
  includeLinksAndCorporateMessage,
}) => {
  const { t } = useTranslation();

  const showEveryoneDefault = (index: number) =>
    index === 0 &&
    !displayAceEntries[0].permissions?.read &&
    isItemAncestorNotCorporate &&
    includeLinksAndCorporateMessage &&
    sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE;

  return (
    <>
      <div
        data-testid={showEveryoneDefault(index) ? 'everyoneDefaultRow' : 'aceRow'}
        className={classNames(
          showEveryoneDefault(index) ? 'fakeTableRowDivider' : 'fakeTableRow',
          'flexColumnContainer',
          'flexNoGrowNoShrink',
        )}
        key={`fakeTableRow-${index}`}>
        <div data-testid="aclModalRow" className="specAceIdentity text-valign-middle width-390 sq-text-primary">
          {(ace.identity.isRedacted || !ace.identity.isEnabled) && (
            <Icon icon="fa-exclamation-triangle" type="danger" testId="aceNotAccessible" />
          )}
          <OverlayTrigger
            placement="right-end"
            key={`${ace.id}-${index}`}
            overlay={popover(ace)}
            trigger="hover"
            transition={false}
            data-testid="aclPopover">
            <span>
              {ace.identity.name} {getAdditionalText(ace, t, currentUserId)}
            </span>
          </OverlayTrigger>
        </div>
        <div className="text-valign-middle min-width-70">{prettyIdentityType(ace.identity.type, t)}</div>
        {_.map(PERMISSIONS, (perm) => (
          <div className="text-center min-width-75" key={`${perm}_${ace.id}`}>
            <AceCheckbox
              name={perm}
              ace={ace}
              setPermissions={setPermissions}
              isAclFromDatasource={isAclFromDatasource}
            />
          </div>
        ))}
      </div>
    </>
  );
};
